<template>
  <v-container fluid class="grey lighten-3">

    <v-card class="mb-5">

        <v-tabs v-model="tab" @change="tabChanged">
          <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <AccountsList v-if="tab == 0"></AccountsList>
          </v-tab-item>

          <v-tab-item>
            <RefreshAccounts v-if="tab == 1"></RefreshAccounts>
          </v-tab-item>

        </v-tabs-items>

    </v-card>

  </v-container>
</template>

<script>
export default {
  name: 'Accounts',

  data () {
    return {
      tab: null,
      items: ['List', 'Refresh'],
      lsTab: 'accounts_tab',
    }
  },

  components: {
    AccountsList: () => import('@/components/accounts/AccountsList.vue'),
    RefreshAccounts: () => import('@/components/accounts/RefreshAccounts.vue')
  },

  methods: {
    tabChanged () {
      localStorage.setItem(this.lsTab, JSON.stringify(this.tab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsTab)) {
      this.tab = JSON.parse(localStorage.getItem(this.lsTab))
    }
 },
}
</script>
